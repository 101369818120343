import React from "react"
import "./HeroHeader.css"
import { Grid, Typography, Container } from "@mui/material"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import banner from "../../assets/new_images/banner.png"
import coin from "../../assets/new_images/mainimge-coin.png"

const HeroHeader = () => {
  const { ref, inView, entry } = useInView()
  return (
    <div className="Hero_header_container" ref={ref}>
      <Container sx={{ maxWidth: "1800px" }} maxWidth={false}>
        <Grid
          container
          spacing={3}
          className="Grid_container_spread_tablet custom_main_container"
        >
          <Grid item xs={12} md={6}>
            <motion.div
              className="hero_header_content"
              initial={inView === false ? { y: 30, opacity: 0 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <h1>Get your instant refund in real-time.</h1>
              <Typography variant="body1">
                Don't wait 5-14 business days to receive your refund when
                returning your purchase to the retailer. <br />
                <br />
                <span style={{ fontWeight: 700 }}>
                  Eliminate refund anxiety. <br />
                  Use Apace to get refunded instantly.
                </span>
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              className="hero_header_coin"
              initial={inView === false ? { y: -800, opacity: 1 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{
                bounce: true,
                type: "spring",
                delay: 1.3,
                stiffness: 70,
              }}
            >
              <img src={coin} alt="coin" />
            </motion.div>
            <motion.div
              className="hero_header_img"
              initial={inView === false ? { y: 30, opacity: 0 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <img src={banner} alt="banner" />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default HeroHeader
